/* eslint-disable @typescript-eslint/return-await */
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { client, imageUploadClient } from "./api-client";
import {
  type UserTransaction,
  type CoinType,
  type UserStats,
  type ListedUser,
  type User,
  type UpdateUserEmail,
  type UpdateUserPassword,
  type UserWalletDetail,
  type Banner,
  type KycUser,
  type DepositAccount,
  type UploadResponse,
} from "utils/types/user.type";

export const useGetLoggedInUser = () =>
  useQuery<User, Error>(
    ["getLoggedInUser"],
    async () => await client(`auth/getLoggedInUser`)
  );

export const useGetTransactions = (
  isApproved?: "true" | "false",
  limit: number = 100, // Default limit is 20
  offset: number = 0 // Default offset is 0
) =>
  useQuery<UserTransaction[], Error>(
    ["getTransactions", isApproved, limit, offset],
    async () =>
      await client(
        `wallet/getTransactionsByAdmin?${
          isApproved ? `&isApproved=${isApproved}` : ""
        }&limit=${limit}&offset=${offset}`
      ),
    {
      keepPreviousData: true, // This keeps the previous data while new data is being fetched
    }
  );

export const useGetStats = () =>
  useQuery<UserStats, Error>(
    ["getStats"],
    async () => await client(`wallet/getStats`)
  );

export const useGetFee = () =>
  useQuery<any, Error>(["getFee"], async () => await client(`wallet/getFee`));

export const useGetUserList = (isAdmin?: boolean) =>
  useQuery<ListedUser[], Error>(
    ["getUserList"],
    async () =>
      await client(`auth/getAllUsers${isAdmin ? "?filterOnlyAdmins=true" : ""}`)
  );

export const useUpdateUser = () => {
  const queryClient = useQueryClient();
  return useMutation<
    unknown,
    Error,
    Partial<{
      userId: string;
      isActive: boolean;
      fullname: string;
      phone: string;
      country: string;
    }>
  >(async (data) => await client(`auth/updateUserActive`, { data }), {
    onSuccess() {
      void queryClient.invalidateQueries(["getUserList"]);
    },
  });
};

export const useGetCoins = () =>
  useQuery<CoinType[], Error>(
    ["getCoins"],
    async () => await client(`coins/getCoins?limit=100`)
  );

export const useUpdateCryptoWithdrawFee = () => {
  const queryClient = useQueryClient();
  return useMutation<unknown, Error, { price: number; coinId: string }>(
    async (data) => await client(`coins/updateCryptoWithdrawFee`, { data }),
    {
      onSuccess() {
        void queryClient.invalidateQueries(["getCoins"]);
      },
    }
  );
};

export const useUpdateFiatePrice = () => {
  const queryClient = useQueryClient();
  return useMutation<
    unknown,
    Error,
    { coinId: string; priceFiatBuy: number; priceFiatSell: number }
  >(async (data) => await client(`coins/updateFiatPrice`, { data }), {
    onSuccess() {
      void queryClient.invalidateQueries(["getCoins"]);
    },
  });
};

export const useUpdateCryptoPrice = () => {
  const queryClient = useQueryClient();
  return useMutation<unknown, Error, { coinId: string; price: number }>(
    async (data) => await client(`coins/updateCryptoToFiatSwapFee`, { data }),
    {
      onSuccess() {
        void queryClient.invalidateQueries(["getCoins"]);
      },
    }
  );
};

export const useUpdateCoinPrice = () => {
  const queryClient = useQueryClient();
  return useMutation<unknown, Error, { price: number; coinId: string }>(
    async (data) => await client(`coins/updatePrice`, { data }),
    {
      onSuccess() {
        void queryClient.invalidateQueries(["getCoins"]);
      },
    }
  );
};

export const useSetFee = () => {
  const queryClient = useQueryClient();
  return useMutation<unknown, Error, { feeName: string; value: number }>(
    async (data) => await client(`wallet/setFee`, { data }),
    {
      onSuccess() {
        void queryClient.invalidateQueries(["getFee"]);
      },
    }
  );
};

export const useGetBanner = () =>
  useQuery<Banner[], Error>(
    ["getBanner"],
    async () => await client(`auth/getBanners?limit=10&offset=0`)
  );

export const useAddBanner = () => {
  const queryClient = useQueryClient();
  return useMutation<unknown, Error, { mediaUrl: string }>(
    async (data) => await client(`auth/addBanner`, { data }),
    {
      onSuccess() {
        void queryClient.invalidateQueries(["getBanner"]);
      },
    }
  );
};
export const useUpdateBanner = () => {
  const queryClient = useQueryClient();
  return useMutation<
    unknown,
    Error,
    {
      bannerId: string;
      mediaUrl: string;
      isActive: boolean;
      isDeleted: boolean;
    }
  >(async (data) => await client(`auth/updateBanner`, { data }), {
    onSuccess() {
      void queryClient.invalidateQueries(["getBanner"]);
    },
  });
};

export const useGetKycUser = (isKycStatusCompleted?: boolean) =>
  useQuery<KycUser[], Error>(
    ["getKycUser", isKycStatusCompleted], // Include the status in the query key to refetch when it changes
    async () => {
      const queryParams = new URLSearchParams({
        limit: "10",
        offset: "0",
      });
      if (isKycStatusCompleted !== undefined) {
        queryParams.append(
          "isKycStatusCompleted",
          isKycStatusCompleted.toString()
        );
      }
      const url = `auth/getKycUsers?${queryParams.toString()}`;
      return await client(url);
    }
  );

export const useUpdateKyc = () => {
  const queryClient = useQueryClient();
  return useMutation<unknown, Error, { userId: string; kycStatus: string }>(
    async (data) => await client(`auth/updateKYCStatus`, { data }),
    {
      onSuccess() {
        void queryClient.invalidateQueries(["getKycUser"]);
      },
    }
  );
};
export const useGetDepositAccount = () =>
  useQuery<DepositAccount[], Error>(
    ["getDepositAccount"],
    async () => await client(`coins/getDepositAccount`)
  );

export const useUpdateDepositAccount = () => {
  const queryClient = useQueryClient();
  return useMutation<
    unknown,
    Error,
    {
      accountType: string;
      isDepositEnabled: boolean;
      isWithdrawEnabled: boolean;
      instructions: string;
      bankTitle: string;
      bankName: string;
      bankNumber: string;
      bankExpiry: string;
      orangeName: string;
      orangeNumber: string;
      mtnName: string;
      mtnNumber: string;
    }
  >(async (data) => await client(`coins/updateAccount`, { data }), {
    onSuccess() {
      void queryClient.invalidateQueries(["getDepositAccount"]);
    },
  });
};

export const useUpdateEmail = () =>
  useMutation<unknown, Error, UpdateUserEmail>(
    async (data) => await client(`auth/changeEmail`, { data })
  );

export const useUpdatePassword = () =>
  useMutation<unknown, Error, UpdateUserPassword>(
    async (data) => await client(`auth/changePassword`, { data })
  );

export const useUpdateAdmin = () => {
  const queryClient = useQueryClient();
  return useMutation<
    unknown,
    Error,
    { userId: string; role: "User" | "Admin" | string }
  >(async (data) => await client(`auth/updateUserRole`, { data }), {
    onSuccess() {
      void queryClient.invalidateQueries(["getUserList"]);
    },
  });
};

export const useGetUserWalletBalance = (id?: string) =>
  useQuery<UserWalletDetail, Error>(
    ["getUserWalletBalance", id],
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    async () => await client(`wallet/getUserWalletWithBalance/${id}`),
    { enabled: !!id }
  );

export const useUpdateTransaction = () => {
  const queryClient = useQueryClient();
  return useMutation<
    unknown,
    Error,
    { transactionId: string; status: string; trxUrl: string }
  >(async (data) => await client(`wallet/updateTransaction`, { data }), {
    onSuccess() {
      void queryClient.invalidateQueries(["getTransactions", "false"]);
    },
  });
};

export const useImageUpload = () => {
  return useMutation<UploadResponse, Error, File>({
    mutationFn: async (file: File) => {
      const formData = new FormData();
      formData.append("file", file);

      // Use the imageUploadClient to handle the file upload
      return await imageUploadClient("media-upload/mediaFiles/image", {
        data: formData,
      });
    },
    onSuccess: (response) => {
      console.log("Image uploaded successfully:", response);
      // Handle successful upload (e.g., update UI, save URL to state, etc.)
    },
  });
};

export const sendTokenToBackend = async (token: string) => {
  try {
    const response = await client(`auth/addUserToken/${token}`, {
      method: "GET", // Assuming it's a POST request
    });

    if (response) {
      console.log("Token sent to backend successfully", response);
      return response;
    }
  } catch (error) {
    console.error("Error sending token to backend:", error);
    throw error;
  }
};
